import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const staticRoutes = [{
    path: '/home',
    name: 'home',
    alias: '/',
    redirect: '/index',
    component: (resolve) => require(['@/views/home'], resolve),
    children: [{
            path: '/lost',
            name: 'notFind',
            meta: {
                title: '页面走丢了'
            },
            component: (resolve) => require(['@/views/lost/notFind'], resolve)
        },
        {
            path: '/index',
            name: 'index',
            meta: {
                title: '知识追寻者'
            },
            component: (resolve) => require(['@/views/index'], resolve)
        },
        {
            path: '/category/:categoryId',
            name: 'category',
            meta: {
                title: '教程'
            },
            component: (resolve) => require(['@/views/paper/category'], resolve),
            children: [{
                path: '/category/:categoryId/article/:articleId',
                name: 'categoryArticle',
                meta: {
                    //title: '教程文章'
                },
                component: (resolve) => require(['@/views/paper/categoryArticleInfo'], resolve)
            }]
        },
        {
            path: '/user/:username',
            name: 'homepage',
            meta: {
                title: '个人主页'
            },
            props: true,
            component: (resolve) => require(['@/views/individual/homepage'], resolve)
        },
        {
            path: '/edit',
            name: 'articleEdit',
            meta: {
                title: '文章编辑'
            },
            component: (resolve) => require(['@/views/individual/articleEdit'], resolve)
        },
        {
            path: '/tag',
            name: 'articleTag',
            meta: {
                title: '标签管理'
            },
            component: (resolve) => require(['@/views/individual/articleTag'], resolve)
        },
        {
            path: '/draft',
            name: 'articleDraft',
            meta: {
                title: '草稿箱'
            },
            component: (resolve) => require(['@/views/individual/articleDraft'], resolve)
        },
        {
            path: '/accuse',
            name: 'artilceAccuse',
            meta: {
                title: '举报查看'
            },
            component: (resolve) => require(['@/views/individual/articleAccuse'], resolve)
        },
        {
            path: '/article/:articleId',
            name: 'articleInfo',
            meta: {
                //title: '文章'
            },
            component: (resolve) => require(['@/views/paper/articleInfo'], resolve)
        },
        {
            path: '/login',
            name: 'login',
            meta: {
                title: '用户登陆'
            },
            component: (resolve) => require(['@/views/login'], resolve)
        },
        {
            path: '/admin/home',
            name: 'layout',
            redirect: '/admin/article/manage',
            meta: {
                title: '后台管理'
            },
            component: (resolve) => require(['@/views/admin/layout'], resolve),
            children: [{
                    path: '/admin/article/manage',
                    name: 'articleManage',
                    meta: {
                        title: '文章管理'
                    },
                    component: (resolve) => require(['@/views/admin/paper/articleManage'], resolve)
                },

                {
                    path: '/admin/user',
                    name: 'user',
                    meta: {
                        title: '用户管理'
                    },
                    component: (resolve) => require(['@/views/admin/sys/user'], resolve)
                },
                {
                    path: '/admin/role',
                    name: 'role',
                    meta: {
                        title: '角色管理'
                    },
                    component: (resolve) => require(['@/views/admin/sys/role'], resolve)
                }
            ]
        }
    ]
}]

const router = new Router({
    routes: staticRoutes,
    mode: 'history' // 采用hash模式 目录锚点会失败
})


router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        window.document.title = to.meta.title
    }

    if (to.path === '/login') {
        return next()
    }
    const token = window.localStorage.getItem('token')
    if (!token && to.path.startsWith('/admin')) {
        return next('/login')
    } else {
        if (!to.matched.length) {
            window.location = '/lost'
            return
        }
        // 路由刷新的时候如果权限为空重新拉取
        if (token) {
            // user 为 undefine 清除token
            if (typeof(store.state.permission.user) == 'undefined') {
                localStorage.removeItem('token')
            } else if (!store.state.permission.user.username) {
                store.dispatch('addUser')
                return next()
            } else {
                return next()
            }
        } else {
            return next()
        }
    }
})

export default router