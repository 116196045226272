import request from '@/http/request'

// 获取文章分类信息（导航栏菜单）
export const getCategoryTree = (params) => {
    return request({
        url: '/article/category/tree',
        method: 'get',
        params: params
    })
}

// 根据分类获取文章列表
export const getArticleInfoByCpath = (cpath) => {
        return request({
            url: `/article/list/${cpath}`,
            method: 'get'
        })
    }
    // 分页获取文章列表
export const getArticleInfo = (params) => {
        return request({
            url: `/article/page`,
            method: 'get',
            params: params
        })
    }
    // 后台文章
export const getManageArticleInfo = (params) => {
        return request({
            url: `/article/manage`,
            method: 'get',
            params: params
        })
    }
    // 获取最新文章
export const getArticleLatest = (params) => {
        return request({
            url: `/article/latest`,
            method: 'get',
            params: params
        })
    }
    // 获取排行版文章
export const getArticleRank = (params) => {
    return request({
        url: `/article/rank`,
        method: 'get',
        params: params
    })
}

// 获取推荐文章
export const getArticleRecommend = (params) => {
    return request({
        url: `/article/recommend`,
        method: 'get',
        params: params
    })
}

// 获取草稿
export const getArticleDraft = (params) => {
    return request({
        url: `/article/draft`,
        method: 'get',
        params: params
    })
}

// 根据id获取文章具体信息
export const getArticleInfoById = (id) => {
    return request({
        url: `/article/info/${id}`,
        method: 'get'
    })
}

// 添加文章
export const addArticleInfo = (params) => {
    return request({
        url: 'article/info',
        method: 'post',
        data: params
    })
}

// 修改文章
export const updateArticleInfo = (id, params) => {
    return request({
        url: `/article/info/${id}`,
        method: 'put',
        data: params
    })
}

// 修改文章状态
export const changeStatus = (id, status) => {
    return request({
        url: `/article/info/status/${id}/${status}`,
        method: 'put'
    })
}

// 删除文章
export const deleteArticleInfo = (id) => {
    return request({
        url: `/article/info/${id}`,
        method: 'delete'
    })
}



// 获取标签
export const getArticleTag = (params) => {
        return request({
            url: `/article/tag`,
            method: 'get',
            params: params
        })
    }
    // 添加标签
export const addArticleTag = (params) => {
        return request({
            url: `/article/tag`,
            method: 'post',
            data: params
        })
    }
    // 修改标签
export const editArticleTag = (id, params) => {
        return request({
            url: `/article/tag/${id}`,
            method: 'put',
            data: params
        })
    }
    // 删除标签
export const deleteArticleTag = (id) => {
        return request({
            url: `/article/tag/${id}`,
            method: 'delete'
        })
    }
    // 登陆
export const login = (params) => {
        return request({
            url: `/login`,
            method: 'post',
            data: params
        })
    }
    // 注册
export const register = (params) => {
        return request({
            url: `system/register`,
            method: 'post',
            data: params
        })
    }
    // 用户列表
export const getUserList = (params) => {
        return request({
            url: `/system/user/list`,
            method: 'get',
            params: params
        })
    }
    // 修改用户
export const editUser = (userId, params) => {
    return request({
        url: `/system/user/${userId}`,
        method: 'put',
        data: params
    })
}
export const editUserInfo = (params) => {
        return request({
            url: `/system/user`,
            method: 'put',
            data: params
        })
    }
    // 删除用户
export const deleteUser = (userId) => {
        return request({
            url: `/system/user/${userId}`,
            method: 'delete'
        })
    }
    // 当前登陆用户信息
export const getCurrentUser = (params) => {
        return request({
            url: `/system/current`,
            method: 'get',
            params: params
        })
    }
    // 当前登陆用户信息
export const getUserByUserName = (username) => {
        return request({
            url: `/system/current/${username}`,
            method: 'get',
        })
    }
    // 角色列表
export const getRoleList = (params) => {
        return request({
            url: `/system/role/list`,
            method: 'get',
            params: params
        })
    }
    // 通过id获取角色
export const getRoleById = (roleId) => {
        return request({
            url: `/system/role/${roleId}`,
            method: 'get'
        })
    }
    // 添加角色
export const addRole = (params) => {
        return request({
            url: `/system/role`,
            method: 'post',
            data: params
        })
    }
    // 修改角色
export const editRole = (roleId, params) => {
        return request({
            url: `/system/role/${roleId}`,
            method: 'put',
            data: params
        })
    }
    // 删除角色
export const deleteRole = (roleId) => {
        return request({
            url: `/system/role/${roleId}`,
            method: 'delete'
        })
    }
    // 通过文章id获取评论
export const getArticleRemarkById = (articleId) => {
        return request({
            url: `/article/remark/${articleId}`,
            method: 'get'
        })
    }
    // 文章评论数量
export const getArticleRemarkCount = (articleId) => {
        return request({
            url: `/article/remark/count/${articleId}`,
            method: 'get'
        })
    }
    // 添加评论
export const addArticleRemark = (params) => {
        return request({
            url: `/article/remark`,
            method: 'post',
            data: params
        })
    }
    //   
export const editArticleRemarkVisiable = (id) => {
        return request({
            url: `/article/remark/${id}`,
            method: 'put',
        })
    }
    // 通过文章id获取点赞数量
export const getArticleApproveCount = (articleId, params) => {
        return request({
            url: `/article/approve/${articleId}`,
            method: 'get',
            params: params
        })
    }
    // 添加点赞
export const addArticleApprove = (params) => {
        return request({
            url: `/article/approve`,
            method: 'post',
            data: params
        })
    }
    // 查询违规信息
export const getArticleAccuse = (params) => {
        return request({
            url: `/article/accuse/`,
            method: 'get',
            params: params
        })
    }
    // 添加违规信息
export const addArticleAccuse = (params) => {
        return request({
            url: `/article/accuse/`,
            method: 'post',
            data: params
        })
    }
    // 修改违规信息
export const editArticleAccuse = (id, params) => {
        return request({
            url: `/article/accuse/${id}`,
            method: 'put',
            data: params
        })
    }
    // 查询违规类型
export const getArticleAccuseType = (params) => {
        return request({
            url: `/article/accuse/type`,
            method: 'get',
            params: params
        })
    }
    // 获取文章分类
export const getArticleClassify = (params) => {
        return request({
            url: `/article/classify`,
            method: 'get',
            params: params
        })
    }
    // 发送邮件
export const sendMail = (params) => {
        return request({
            url: `/mail/send/`,
            method: 'post',
            data: params
        })
    }
    // 上传图片
export const uploadImg = (params) => {
        return request({
            url: `/cos/upload/`,
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: params
        })
    }
    // 删除图片
export const deleteImg = (path) => {
        return request({
            url: `/cos/images/${path}`,
            method: 'delete'
        })
    }
    // 获取封面
export const getCover = (params) => {
    return request({
        url: `/resource/cover`,
        method: 'get',
        params: params
    })
}
export default {
    getCategoryTree,
    getArticleInfoByCpath,
    getArticleInfo,
    getManageArticleInfo,
    getArticleLatest,
    getArticleRank,
    getArticleRecommend,
    getArticleDraft,
    getArticleInfoById,
    addArticleInfo,
    updateArticleInfo,
    changeStatus,
    deleteArticleInfo,
    getArticleTag,
    addArticleTag,
    editArticleTag,
    deleteArticleTag,
    login,
    register,
    getUserList,
    editUser,
    editUserInfo,
    deleteUser,
    getRoleList,
    getCurrentUser,
    getUserByUserName,
    getRoleById,
    addRole,
    editRole,
    deleteRole,
    getArticleRemarkById,
    getArticleRemarkCount,
    editArticleRemarkVisiable,
    addArticleRemark,
    getArticleApproveCount,
    addArticleApprove,
    getArticleAccuse,
    addArticleAccuse,
    editArticleAccuse,
    getArticleAccuseType,
    getArticleClassify,
    sendMail,
    uploadImg,
    deleteImg,
    getCover
}