import store from '@/store'

export default {
    install(Vue, options) {
        Vue.prototype.permission = function showComponent(value) {
            let permission = store.state.permission.user.authorities
            let hasPermission = permission.some(val => {
                return value.includes(val.authority)
            })
            return hasPermission
        }
    }
}