import Vue from 'vue'
import App from './App.vue'
import router from './router'
import api from '@/http'
import '@/assets/css/global.css'
//import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Fragment from 'vue-fragment'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import store from './store'
import permission from './components/permission'
import utils from '@/utils'
import infiniteScroll from 'vue-infinite-scroll'
import '@/assets/css/iconStyle.css'
import VueWechatTitle from "vue-wechat-title";
// import 'highlight.js/styles/atelier-lakeside-dark.css'
// import 'highlight.js/styles/tomorrow-night.css'
// import Highlight from '@/utils/highlight.js'
//import Viewer from 'v-viewer';
//import 'viewerjs/dist/viewer.css';
//Vue.use(Viewer);
//import ElementUI from 'element-ui'
//Vue.use(ElementUI)

Vue.use(VueWechatTitle);
Vue.use(infiniteScroll)
Vue.use(mavonEditor)
Vue.use(Fragment.Plugin)
Vue.use(api)
Vue.use(permission)
Vue.use(utils)
Vue.config.productionTip = false

import {
    Pagination,
    Button,
    Dialog,
    Menu,
    Submenu,
    MenuItem,
    MenuItemGroup,
    Input,
    Form,
    FormItem,
    Tag,
    Breadcrumb,
    BreadcrumbItem,
    MessageBox,
    Message,
    // Notification,
    Tree,
    Image,
    // TimeSelect,
    // TimePicker,
    DatePicker,
    Avatar,
    Row,
    Col,
    Container,
    Header,
    Aside,
    Main,
    Footer,
    Card,
    Divider,
    Tooltip,
    Table,
    TableColumn,
    Select,
    Option,
    OptionGroup,
    Switch,
    Alert,
    Upload,
    Popover,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    InputNumber
} from 'element-ui';

Vue.use(Pagination);
Vue.use(Button);
Vue.use(Dialog);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Input);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tag);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
// Vue.use(MessageBox);
// Vue.use(Message);
Vue.component(MessageBox.name, MessageBox);
// Vue.use(Notification);
Vue.use(Tree);
Vue.use(Image);
// Vue.use(TimeSelect);
// Vue.use(TimePicker);
Vue.use(Avatar);
Vue.use(Row);
Vue.use(Col);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main)
Vue.use(Footer)
Vue.use(Card)
Vue.use(Divider)
Vue.use(Tooltip)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(DatePicker)
Vue.use(Switch)
Vue.use(Alert)
Vue.use(Upload)
Vue.use(Popover)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(InputNumber)

Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')