import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'

// 创建axios实例
const https = axios.create({
    baseURL: 'https://zszxz.com/api/v1'
        // baseURL: 'http://localhost:23456/api/v1'
        // timeout: 10000
})

https.interceptors.request.use(
    config => {
        if (localStorage.token) {
            // 将token设置成请求头
            config.headers.access_token = localStorage.token
        }
        return config
    },
    err => {
        console.log(err)
        return Promise.reject(err)
    }
)

https.interceptors.response.use(function(response) {
    if (response.data.code === 401) {
        Message.error(response.data.msg)
        window.localStorage.clear()
        router.replace({ path: '/login' })
    } else if (response.data.code !== 200 && response.data.code !== 401) {
        Message.error(response.data.msg)
    }
    return response
}, function(error) {
    console.log(error)
    return Promise.reject(error)
})

export default https