import apis from '@/http/apis'

const permission = {
    state: {
        user: {}
    },
    mutations: {
        addUser(state, user) {
            state.user = user
        },
        clear(state) {
            state.user = {}
        }
    },
    actions: {
        async addUser(context) {
            const { data: res } = await apis.getCurrentUser()
            context.commit('addUser', res.data)
        },
        clear(context) {
            context.commit('clear')
        }
    }
}

export default permission